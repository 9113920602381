<template>
    <div class="con-wrap">
        <CarrotTitle title="사보 관리"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <NewsLetterTab v-if="bbs.idx" :idx="bbs.idx" :tidx="4" :mode="2"></NewsLetterTab>
                <table v-if="bbs.isLoaded==true" class="table-row">
                    <colgroup>
                        <col width="180">
                        <col width="*">
                        <col width="180">
                        <col width="200">
                        <col width="180">
                        <col width="200">
                    </colgroup>                    
                    <tbody v-for="(irow, i) in bbs.info" :key="i">
                        <tr>
                            <th rowspan="7">
                                Program<br>Report
                                <br>{{i+1}}
                            </th>
                            <th>사보명</th>
                            <td>
                                {{ irow.magazine_name }}
                            </td>
                        </tr>
                        <tr>
                            <th>회사/부서명</th>
                            <td colspan="5">
                                {{ irow.company }}
                            </td>
                        </tr>
                        <tr>
                            <th>프로그램명</th>
                            <td colspan="5">
                                {{ irow.program }}
                            </td>
                        </tr>
                        <tr>
                            <th>본문요약</th>
                            <td colspan="5">
                                {{ irow.summary }}
                            </td>
                        </tr>

                        <tr v-if="irow.file1_name">
                            <th>썸네일</th>
                            <td colspan="5">
                                <a :href="irow.file1_url" :download="irow.file1_name" class="btn-view" target="_blank">{{ irow.file1_name }}</a>
                            </td>
                        </tr>
                        <tr v-if="irow.file2_name">
                            <th>PC 이미지</th>
                            <td colspan="5">
                                <a :href="irow.file2_url" :download="irow.file2_name" class="btn-view" target="_blank">{{ irow.file2_name }}</a>
                            </td>
                        </tr>
                        <tr v-if="irow.file3_name">
                            <th>모바일 이미지</th>
                            <td colspan="5">
                                <a :href="irow.file3_url" :download="irow.file3_name" class="btn-view" target="_blank">{{ irow.file3_name }}</a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <button class="btn-default float-left mt-30" @click="bbs.showList()">목록</button>
                <button class="btn-default float-left mt-30 ml-10" @click="bbs.doDelete()">삭제</button>
                <button class="btn-default float-right mt-30" @click="bbs.showMod()">수정</button>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import NewsLetterTab from '@/components/common/NewsLetterTab.vue'
import Swal from 'sweetalert2'

export default {
    layout:"ResourceManagement",
    components: {
      NewsLetterTab
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const toast = useToast()

        const bbs = reactive({
            isLoaded : false,

            idx : 0,
            info : {},

            showList: () => {
                router.push({ name:"ResourceManagement-NewsLetterManagementList" });
            },

            doDelete : () => {
                Swal.fire({
                    title : "사보관리",
                    //text: '삭제하시겠습니까?',
                    html: '다른 탭의 항목이 함께 삭제됩니다.<br>삭제하시겠습니까?',
                    showCancelButton: true,
                }).then((res) => {
                    if (res.isConfirmed) {
                        let params = {
                            idx : bbs.idx
                        };
                        axios.post("/rest/resourceManagement/newsLetterDelAll", params).then((res) => {
                            if( res.data.err == 0 ){
                                router.push({
                                    name: 'ResourceManagement-NewsLetterManagementList'
                                })
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            showMod: () => {
                router.push({
                    name   : 'ResourceManagement-NewsLetterManagement_tab4-idx',
                    params : { idx:bbs.idx }
                });
            },

            getInfo: () => {
                let params = {
                    idx : bbs.idx,
                    kind : "newsletter"
                };
                axios.get("/rest/resourceManagement/reportView", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.info = res.data.idx_list;
                        if(res.data.is_del=='Y'){
                            router.push({
                                name: 'ResourceManagement-NewsLetterManagementList'
                            })
                        }

                        if(!res.data.idx_list){
                            bbs.info = [{
                              magazine_name : '',
                              company : '',
                              program : '',
                              summary : '',
                              file1_name : '',
                              file2_name : '',
                              file3_name : '',
                            }];
                        }
                        bbs.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });
        onMounted(() => {
            // Mounted
            bbs.idx = route.params.idx;
            if ( !bbs.idx ) {
                router.go(-1);
            } 
            bbs.getInfo();
        });

        watch(() => route.params.idx, (nVal, oVal) => {
            if( nVal != oVal && nVal > 0 ){
                bbs.idx = nVal;
                bbs.getInfo();
            }
        })

        return { bbs };
    }
}
</script>

<style lang="scss" scoped>
.board {
    .contents {
        img {
            max-width : 100%;
        }
    }
}
</style>